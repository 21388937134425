import React from "react"
import "../../styles.css"

const FadeInSection = props => {
    const { children, timeout } = props
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                setTimeout(() => {
                    if (entry.isIntersecting) {
                        setVisible(entry.isIntersecting);
                    }
                }, timeout ? timeout : 0)
            });
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);
    return (
        <div
            className={[
                "fade-in-section",
                isVisible ? "is-visible" : "",
            ].join(" ")}
            ref={domRef}
        >
            {children}
        </div>
    )
}

export default FadeInSection
