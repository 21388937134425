import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import HeroCarousel from "../components/HeroCarousel";
import Header from "../components/Header";
import { Carousel } from "react-bootstrap";
import $ from "jquery";
import FadeInSection from "../components/FadeInSection/FadeInSection";
import { binarySearch } from "../utils";

const IndexPage = ({ data }) => {
  const content = data && data?.sanityPage.content;
  const hero = data && data?.sanityPage.hero;
  const heroMobile = data && data?.sanityPage.heroMobile;
  const viewportHeight = typeof window !== "undefined" && window.innerHeight;
  const viewportwidth = typeof window !== "undefined" && window.innerWidth;
  const [index, setIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState({ transition: "none" });
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState("");

  const effectInImages = () => {
    let box = document.getElementById("images");
    let header = document.getElementById("header");
    header.classList.add("hidden");
    if (box.classList.contains("hidden")) {
      box.classList.remove("hidden");
      setTimeout(function () {
        box.classList.remove("visuallyhidden");
      }, 20);
    } else {
      box.classList.add("visuallyhidden");
      box.addEventListener(
        "transitionend",
        function (e) {
          box.classList.add("hidden");
        },
        {
          capture: false,
          once: true,
          passive: false,
        }
      );
    }
  };

  let comulativeTable = [0];
  let countRowThree = [0];
  let countRowTwo = [0];

  const show = {
    opacity: 1,
    height: "auto",
  };

  const hidden = {
    opacity: 0,
    height: 0,
  };

  const goScroll = () => {
    let scrollFrom = 0;

    if (viewportwidth > 768) {
      const row = binarySearch(
        comulativeTable,
        index,
        0,
        comulativeTable.length - 1
      );
      scrollFrom = row * height + viewportHeight;
    } else {
      if (width === "2/3") {
        scrollFrom =
          height * countRowTwo[index] + height * countRowThree[index] * 2;
        scrollFrom += height;
      } else {
        scrollFrom =
          height * countRowThree[index] + (height * countRowTwo[index]) / 2;

        scrollFrom += height;
      }
    }

    $("html, body").animate(
      {
        scrollTop: scrollFrom,
      },
      10
    );
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const renderData = (d) => {
    return d.map((e) => {
      return e.images.map((images, index) => {
        const lightbox = getImage(images?.lightbox?.asset);
        const image = getImage(images.image.asset);

        return (
          <Carousel.Item key={index} style={transition}>
            <GatsbyImage
              image={lightbox ? lightbox : image}
              placeholder="none"
              alt="img"
              className="h-100 w-100"
              objectFit="contain"
            />
          </Carousel.Item>
        );
      });
    });
  };

  let x = 0;
  let y = 0;

  const renderContentData = (content) => {
    return content.map((e) => {
      y++;
      comulativeTable[y] = comulativeTable[y - 1] + e.images.length;

      return e.images.map((images, index2) => {
        const name = images.name;
        const title = images.title;
        const width = images.width;
        x++;

        if (width === "1/3") {
          countRowThree[x] = countRowThree[x - 1] + 1;
          countRowTwo[x] = countRowTwo[x - 1];
        } else {
          countRowThree[x] = countRowThree[x - 1];
          countRowTwo[x] = countRowTwo[x - 1] + 1;
        }

        const image = getImage(images?.image?.asset);

        return (
          <div
            id={x}
            key={index2}
            className={
              width === "1/3"
                ? "col-sm-12 col-md-4 container-img bg-black"
                : "col-sm-12 col-md-8 container-img bg-black"
            }
            style={{ padding: 0 }}
            onClick={(e) => {
              setOpen(true);
              setHeight($(`#${e.target.name}`).height());
              setWidth(width);
              setIndex(parseInt(e?.target.name) - 1);
              effectInImages();
              setTransition({ transition: "transform .6s ease-in-out" });
            }}
          >
            <FadeInSection timeout={200 * index2}>
              <img
                src={image.images.fallback.src}
                name={x}
                alt="img"
                style={{ maxWidth: "102%"}}
                className="img"
              />

              <div className="gradient" style={{ pointerEvents: "none" }}>
                <p className="name" style={{ pointerEvents: "none" }}>
                  {name}{" "}
                  <span className="title" style={{ pointerEvents: "none" }}>
                    {title}
                  </span>
                </p>
              </div>
            </FadeInSection>
          </div>
        );
      });
    });
  };

  return (
    <>
      <Header grad  path="OVERVIEW" />
      <div style={{ backgroundColor: "black", height: "100vh" }}>
        <div className="desktop" style={!open ? show : hidden}>
          {!open && <HeroCarousel data={hero} />}
        </div>

        <div className="mobile" style={!open ? show : hidden}>
          {!open && <HeroCarousel data={heroMobile} />}
        </div>

        <div className="carousel" style={open ? show : hidden}>
          <div className="float-right">
            <button
              style={!open ? { display: "none" } : {}}
              className="btn close-carousel"
              onClick={() => {
                setTransition({ transition: "none" });
                setOpen(false);
                effectInImages();
                goScroll();
                let header = document.getElementById("header");
                header.classList.remove("hidden");
              }}
            />{" "}
          </div>

          {open && (
            <Carousel
              activeIndex={index}
              indicators={false}
              onSelect={handleSelect}
            >
              {renderData(content)}
            </Carousel>
          )}
        </div>

        <div
          className="row"
          id="images"
          style={{ transition: "all 0.6s linear", backgroundColor: "black" }}
        >
          {renderContentData(content)}
        </div>
      </div>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query MyQuery {
    sanityPage {
      hero {
        ... on SanityImage {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
      heroMobile {
        ... on SanityImage {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
      content {
        ... on SanityImageRow {
          images {
            name
            title
            width
            image {
              asset {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
            }
            lightbox {
              asset {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
            }
          }
        }
      }
    }
  }
`;
