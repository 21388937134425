import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Carousel } from "react-bootstrap";
import FadeInSection from "../FadeInSection/FadeInSection";

const Carusel = ({ data }) => {
 
  const renderData = (data) => {
    return data.map((e, index) => {
      const image = getImage(e.asset);
      return (
        <Carousel.Item key={index}  translate={2500} style={{height : '100vh'}}>
          <GatsbyImage
            image={image}
            alt="img"
            className="h-100 w-100"
            objectFit="cover"
          />
        </Carousel.Item>
      );
    });
  };

  return (
      <FadeInSection timeout={200}>
        <Carousel interval={2500} fade controls={false} pause={false}  style={{ top: 0 }} indicators={false}>
          {renderData(data)}
        </Carousel>
      </FadeInSection>
  );
};

export default Carusel;
